@use '@/styles/utils/mixins.scss' as *;

.getInContactWrapper {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  .getInContactBox {
    background-color: $white;
    border-radius: 1.25rem;
    padding: 2rem;
    @include tab-des() {
      padding: 2rem 1.875rem;
    }
    .getInContactList {
      display: flex;
      list-style: none;
      padding: 0;
      margin: 0;
      align-items: center;
      justify-content: center;
      gap: 3.25rem;
      flex-wrap: wrap;
      @include tab-des() {
        flex-direction: column;
        gap: 1.5rem;
      }
      li {
        display: flex;
        align-items: center;
        gap: 3.25rem;
        @include tab-des() {
          flex-direction: column;
          gap: 1.5rem;
          align-items: center;
          width: 100%;
          text-align: center;
        }
        &:not(:last-child) {
          &::after {
            content: '';
            background-color: $primaryAlt;
            width: 0.063rem;
            height: 5.5rem;
            flex: 0 0 auto;
            @include tab-des() {
              height: 0.063rem;
              width: 100%;
            }
          }
        }
      }
      .getInContactListItem {
        display: flex;
        align-items: center;
        gap: 1.25rem;
        @include tab-des() {
          gap: 0.5rem;
          flex-direction: column;
          width: 100%;
        }
        .getInContactListTitle {
          font-size: 1rem;
          color: $primary;
          text-transform: uppercase;
          font-weight: 500;
          @include tab-des() {
            br {
              display: none;
            }
          }
        }
        .getInContactListContact {
          font-size: 2.125rem;
          line-height: 1;
          color: $dark;
          font-weight: 500;
          transition: all 0.2s ease-in-out;
          @include tab-des() {
            font-size: 1.25rem;
          }
        }
        a.getInContactListContact {
          &:hover,
          &:focus,
          &:active {
            color: $primaryAlt;
          }
        }
      }
    }
  }
}
